import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '~components/button';
import Collapsible from '~components/collapsible';

const FAQ = memo(({ city }) => {
  const intl = useIntl();

  return (
    <div className="c-faq-section">
      <h2 className="l-headline--secondary">
        {intl.formatMessage(
          {
            id: 'containers.city.seo.faq-section.headline',
            defaultMessage: 'Most asked questions',
          },
          { city }
        )}
      </h2>
      <div className="c-faq-section__wrapper">
        <Collapsible.Wrapper className="c-faq-section__collapsible c-faq-section__collapsible--first">
          <Collapsible.Header>
            <div className="c-faq-section-item__headline">
              {intl.formatMessage(
                {
                  id: 'containers.city.seo.faq-section.section-one.headline',
                  defaultMessage: 'Section one headline',
                },
                { city }
              )}
            </div>
          </Collapsible.Header>
          <Collapsible.Content>
            {intl.formatMessage(
              {
                id: 'containers.city.seo.faq-section.section-one.content',
                defaultMessage: 'Section one content',
              },
              { city }
            )}
          </Collapsible.Content>
        </Collapsible.Wrapper>
        <Collapsible.Wrapper className="c-faq-section__collapsible">
          <Collapsible.Header>
            <div className="c-faq-section-item__headline">
              {intl.formatMessage(
                {
                  id: 'containers.city.seo.faq-section.section-two.headline',
                  defaultMessage: 'Section two headline',
                },
                { city }
              )}
            </div>
          </Collapsible.Header>
          <Collapsible.Content>
            {intl.formatMessage(
              {
                id: 'containers.city.seo.faq-section.section-two.content',
                defaultMessage: 'Section two content',
              },
              { city }
            )}
          </Collapsible.Content>
        </Collapsible.Wrapper>
        <Collapsible.Wrapper className="c-faq-section__collapsible">
          <Collapsible.Header>
            <div className="c-faq-section-item__headline">
              {intl.formatMessage(
                {
                  id: 'containers.city.seo.faq-section.section-three.headline',
                  defaultMessage: 'Section three headline',
                },
                { city }
              )}
            </div>
          </Collapsible.Header>
          <Collapsible.Content>
            {intl.formatMessage(
              {
                id: 'containers.city.seo.faq-section.section-three.content',
                defaultMessage: 'Section three content',
              },
              { city }
            )}
          </Collapsible.Content>
        </Collapsible.Wrapper>
      </div>
      <div className="c-faq-section__button">
        <Link to={`/${intl.formatMessage({ id: 'routes.faq' })}`}>
          <Button className="c-btn--text">
            {intl.formatMessage(
              {
                id: 'containers.city.seo.faq-section.button',
                defaultMessage: 'Learn more',
              },
              { city }
            )}
          </Button>
        </Link>
      </div>
    </div>
  );
});

export default FAQ;
