import React from 'react';
import { useIntl } from 'react-intl';

import AboutPlatform from '~components/about-platform';

const About = ({ city }) => {
  const intl = useIntl();

  return (
    <AboutPlatform
      alt={intl.formatMessage(
        {
          id: 'containers.city.seo.about.image-alt',
          defaultMessage: 'Domestic help',
        },
        { city }
      )}
      headline={intl.formatMessage(
        {
          id: 'containers.city.seo.about.headline',
          defaultMessage: 'About this platform',
        },
        { city }
      )}
      content={intl.formatMessage(
        {
          id: 'containers.city.seo.about.content',
          defaultMessage: '',
        },
        { city }
      )}
    />
  );
};

export default About;
