import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { AuthContext } from '~contexts/auth';

import Button from '~components/button';

const CTA = ({ city, locationId }) => {
  const intl = useIntl();
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return null;
  }

  return (
    <div className="c-cta">
      <div className="c-cta__headline">
        {intl.formatMessage(
          {
            id: 'containers.city.seo.cta.headline',
            defaultMessage: 'Find a great cleaner or client in {city}',
          },
          { city }
        )}
      </div>
      <div className="c-cta__buttons">
        <Link to="/intake/client">
          <Button>
            {intl.formatMessage(
              {
                id: 'containers.city.seo.cta.buttons.cleaner',
                defaultMessage: "I'm looking for a cleaner",
              },
              { city }
            )}
          </Button>
        </Link>
        <Link to="/intake/worker">
          <Button className="c-btn--secondary">
            {intl.formatMessage(
              {
                id: 'containers.city.seo.cta.buttons.client',
                defaultMessage: "I'm looking for a client",
              },
              { city }
            )}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default CTA;
