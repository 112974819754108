import React from 'react';
import { useIntl } from 'react-intl';

import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';

import Testimonials from '~containers/testimonials';
import HowItWorks from '~containers/how-it-works';

import Header from './header';
import List from './list';
import CTA from './cta';
import About from './about';
import FAQ from './faq';

const City = ({ location }) => {
  const intl = useIntl();

  useLayout({
    displayCities: true,
    displayNeighborhoods: false,
    displaySignIn: true,
    displayTopCTABar: true,
    logoToBottom: true,
    locationId: location?.locationId,
  });
  useSeo(
    intl.formatMessage(
      {
        id: 'containers.city.seo.title',
      },
      { city: location?.description }
    )
  );

  return (
    <>
      <Header city={location?.description} locationId={location?.locationId} />
      <section className="c-page l-container c-page--homepage">
        <Testimonials />
        <HowItWorks />
        <List city={location?.description} locationId={location?.locationId} />
        <FAQ city={location?.description} />
        <About city={location?.description} />
        <CTA city={location?.description} locationId={location?.locationId} />
      </section>
    </>
  );
};

export default City;
